import sha256 from 'sha256';

import { UserInfo } from '../redux/slices/userinfo';

export function appWebVersion() {
	return APP_WEB_VERSION; // eslint-disable-line no-undef
}

export function opaqueUserId(data: UserInfo) {
	return sha256(data.masterSipId + data.sub);
}

export function opaqueAccountId(data: UserInfo) {
	return sha256(data.masterSipId);
}

export function environment() {
	if (window.location.hostname === 'app.sipgate.com') {
		return 'live';
	}

	if (window.location.hostname === 'app.dev.sipgate.com') {
		return 'dev';
	}

	return 'local';
}

export function normalizePath(pathname: string) {
	return pathname
		.replace(
			/\/([0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12})/g,
			'/:uuid'
		)
		.replace(
			/\/([0-9]{7}-\d+-[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}:[^/]*)/g,
			'/:api-client'
		)
		.replace(/\/(\d{7})([a-z])(\d+)(\/|$)/g, '/:fullExtensionId[$2]/')
		.replace(/\/([a-z])(\d+)(\/|$)/g, '/:extensionId[$1]/')
		.replace(/\/(\d{7})(\/|$)/g, '/:masterSipId/')
		.replace(/\/(\d+)(\/|$)/g, '/:id/');
}
