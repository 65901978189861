import * as amplitude from '@amplitude/analytics-browser';
import { EnrichmentPlugin, SpecialEventType } from '@amplitude/analytics-types';

import { UserInfo } from '../redux/slices/userinfo';
import { environment, normalizePath, opaqueAccountId, opaqueUserId } from './utils';
import { EventProperties } from './amplitude.types';

function getToken() {
	if (environment() === 'live') {
		return '';
	}

	return 'df5067b9b5c43b6f5884293c9d15f3eb';
}

const replaceStringValues = (
	obj: Record<string, any>,
	replacementFunction: (value: string) => string
): Record<string, any> => {
	const traverse = (input: any): any => {
		if (typeof input === 'string') {
			return replacementFunction(input);
		}
		if (Array.isArray(input)) {
			return input.map(traverse);
		}
		if (input && typeof input === 'object') {
			return Object.fromEntries(
				Object.entries(input).map(([key, value]) => [key, traverse(value)])
			);
		}
		return input;
	};
	return traverse(obj);
};

export const pageViewTrackingEnrichment = (): EnrichmentPlugin => {
	return {
		name: 'page-view-tracking-enrichment',
		type: 'enrichment',
		setup: async () => undefined,
		execute: async event => {
			if (event.event_type === SpecialEventType.REVENUE) return event;

			if (!event.event_properties) return event;

			const properties = event.event_properties as Record<string, unknown> | undefined;

			return {
				...event,
				event_properties: {
					...replaceStringValues(event.event_properties, (value: string) => normalizePath(value)),
					'[Amplitude] Page Title': properties
						? normalizePath(
								(properties['[Amplitude] Page Path'] as string) || window.location.pathname
							)
						: '',
				},
			};
		},
	};
};

export const initAmplitude = (userInfo: UserInfo, isPreview: boolean) => {
	if (getToken() === '') {
		return;
	}

	amplitude.add(pageViewTrackingEnrichment());

	amplitude.init(getToken(), {
		serverZone: 'EU',
		trackingOptions: {
			ipAddress: false,
		},
		autocapture: {
			fileDownloads: false,
			formInteractions: false,
			elementInteractions: false,
			attribution: false,
			sessions: false,
		},
	});

	const identifyObj = new amplitude.Identify();
	identifyObj.set('account_id', opaqueAccountId(userInfo));
	identifyObj.set('test_account', userInfo.isTestAccount);
	identifyObj.set('domain', userInfo.domain);
	identifyObj.set('product', userInfo.product);
	identifyObj.set('admin', userInfo.isAdmin);
	identifyObj.set('preview', isPreview);
	identifyObj.set('client', 'appweb');

	amplitude.identify(identifyObj, {
		user_id: opaqueUserId(userInfo),
	});
};

export const track = (eventProperties: EventProperties) => {
	if (getToken() === '') return;
	amplitude.track(eventProperties.name, eventProperties.properties);
};
